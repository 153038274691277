import { i18n } from '@/core/i18n';

const occupationRequired = (v: any) => !!v || i18n.t('validationMessages.selectOccupation');
const numericTextLength = (v: string) => v.length === 6 || v.length === 7 || i18n.t('validationMessages.exactDigitsNumber');
const customValidator = (translationKey: string, forceError: boolean) => (v: any) => v || !forceError || i18n.t(translationKey);

export const validators = {
  occupationRequired,
  numericTextLength,
  customValidator
};

export default validators;
