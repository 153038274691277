import axios from 'axios';

import { EnvironmentVariable } from '@/common/types';

let environmentVariables = { ...process.env } as Record<EnvironmentVariable, string>;

export async function requestEnvironmentVariables() {
  try {
    const res = await axios.get(`/config.json?timestamp=${new Date().getTime()}`);
    environmentVariables = { ...environmentVariables, ...res.data };
  } catch (err) {
    console.error(err);
  }
}

export function getEnvironmentVar(type: EnvironmentVariable) {
  return environmentVariables[type];
}
