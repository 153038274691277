export type OccupationOrigin = 'qvp' | 'svp';

export interface Occupation {
  key: number;
  origin?: OccupationOrigin;
  name: {
    english: string;
    arabic: string;
  }
}
