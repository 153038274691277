import 'material-icons/iconfont/filled.css';
import 'material-icons/iconfont/outlined.css';

import { requestEnvironmentVariables } from '@/common/utils';

const runApp = async () => {
  await requestEnvironmentVariables();

  const vm = await import('./app');

  vm.default.$mount('#app');
};

runApp();
