export function isLetter(input: any) {
  const char = input.key; // Get the character
  if (/^[\s\p{L}]+$/u.test(char)) return true;
  return input.preventDefault();
}

export function catchNumbers(event: any) {
  if (event.key !== 'Meta' && event.key !== 'v') {
    const value = event.clipboardData.getData('text/plain');
    if (/\d/.test(value)) return event.preventDefault();
  }
  return event;
}

