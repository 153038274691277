import Vue from 'vue';
import VueI18n from 'vue-i18n';

import { Language } from '@/common/types';
import client from '@/core/api/client';
import vuetify from '@/plugins/vuetify';

import defaultTranslations from './en';

Vue.use(VueI18n);

export const DEFAULT_LANGUAGE = Language.en;

export const i18n = new VueI18n({
  locale: DEFAULT_LANGUAGE,
  fallbackLocale: DEFAULT_LANGUAGE,
  silentTranslationWarn: true,
  messages: { [DEFAULT_LANGUAGE]: defaultTranslations },
});

const loadedLanguages = [DEFAULT_LANGUAGE]; // our default language that is preloaded

function setI18nLanguage(lang: Language) {
  i18n.locale = lang;
  const root = document.querySelector('html');
  const isRtl = lang === Language.ar;
  client.defaults.headers.common['Accept-Language'] = lang;
  vuetify.framework.rtl = isRtl;
  if (root) {
    root.setAttribute('lang', lang);

    root.setAttribute('dir', isRtl ? 'rtl' : 'ltr');
  }
  return lang;
}

export async function loadLanguageAsync(lang: Language) {
  localStorage.setItem('lang', lang);

  if (i18n.locale === lang || loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  const msgs = await import(/* webpackChunkName: "lang-[request]" */ `@/core/i18n/${lang}.ts`);

  i18n.setLocaleMessage(lang, msgs.default);
  loadedLanguages.push(lang);
  return setI18nLanguage(lang);
}
