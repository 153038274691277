import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  rtl: true,
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#14415A',
        primaryHover: '#148287',
        secondaryHover: '#FAB414'
      },
    },
  },
});
