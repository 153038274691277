export type EnvironmentVariable =
  | 'NODE_ENV'
  | 'VUE_APP_QVP_SPA_URL'
  | 'VUE_APP_QVP_API_URL'
  | 'VUE_APP_SVP_SPA_URL'
  | 'VUE_APP_SVP_API_URL'
  | 'VUE_APP_FACEBOOK_URL'
  | 'VUE_APP_TWITTER_URL'
  | 'VUE_APP_LINKEDIN_URL'
  | 'VUE_APP_YOUTUBE_URL'
