export enum LocalStorageKey {
  Language = 'lang',
}

function getItem(key: LocalStorageKey) {
  return localStorage.getItem(key);
}

function setItem(key: LocalStorageKey, token: string | null) {
  if (token) {
    localStorage.setItem(key, token);
  } else {
    localStorage.removeItem(key);
  }
}


export const getLanguage = () => getItem(LocalStorageKey.Language);
export const setLanguage = (language: string | null) => setItem(LocalStorageKey.Language, language);
