const enTranslations = {
  common: {
    professionalAccreditation: 'Professional Accreditation',
  },
  landing: {
    navigationSection: {
      qvp: 'Qualification Verification Program ',
      qvpDescription: 'The Qualification Verification Program verifies the validity and alignment of the qualifications and experience of the expatriate worker with the professional requirements of the Saudi labor market.',
      svp: 'Skills Verification Program',
      svpDescription: 'The Skills Verification Program ensures that the professional worker has the necessary skills by performing a practical and theoretical test in their field of specialization.',
    },
    accreditationProgramTitle: 'Professional Accreditation Program',
    accreditationProgramDescription: 'The Professional Accreditation Program is an extension of the Kingdom\'s efforts to create a solid labor market that responds to external variables as part of the package of labor programs in Saudi Arabia, which aims to verify the qualifications and skills of foreign workers of all categories, in addition to regulating their entry into the Kingdom.',
    findProgram: 'Find a verification program',
    yourOccupation: 'Your occupation',
    yourCode: 'Your occupation code',
    enterOccupationCode: 'Enter occupation code',
    searchForProgram: 'Search for a program',
    proceedToProgram: 'Proceed to program ',
    selectOccupation: 'Select an occupation',
    search: 'Search',
    occupationCodeDescription: 'Your occupation code can be found in your work visa.',
  },
  appBar: {
    platformLanguage: 'Platform language',
    SVP: 'Skills Verification Program',
    QVP: 'Qualification Verification Program',
    arabic: 'العربية',
    english: 'English'
  },
  footer: {
    copyright: 'Copyright © {currentYear} PACC. All rights reserved.',
    privacyPolicy: 'Privacy Policy',
    termsOfUse: 'Terms of Use',
  },
  validationMessages: {
    selectOccupation: 'Select occupation or provide code.',
    exactDigitsNumber: 'Code must be 6-7 digits.',
    occupationCodeNotExist: 'Code doesn’t exist.',
  }
};

export default enTranslations;
